<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/agenda/new"
        editPath="/agenda/"
        :expander="false"
        subtitle="Agendas"
        tooltip="Cadastro de agendas"
        nomeTelaDoManual="agenda-list"
    >
        <template #columns>
            <Column :sortable="true" field="id" header="Código"></Column>
            <Column :sortable="true" field="nome" header="Nome"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Alterado" :sortable="true" sortField="updated_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import AreaMedicaService from '../../services/AreaMedicaService';

export default {
    data() {
        return {
            service: null
        };
    },
    created() {
        this.service = new AreaMedicaService('/agendas');
    }
};
</script>
